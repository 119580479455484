<template>
    <div id="editTagPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editTagModel"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="tag_editTagType"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("tag_editTagType") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group row validated">
                                                    <div class="col-lg-6">
                                                        <label for="editTagTypeForm_nameInput">{{ $t("tag_tagTypeNameLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                            </div>
                                                            <input v-model.trim="modelName" @input="$v.modelName.$touch()" type="text" class="form-control" id="editTagTypeForm_nameInput" :placeholder="$t('tag_inputInTagTypeModelName')" />
                                                            <div v-if="!$v.modelName.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="!$v.modelName.minLen || !$v.modelName.correctValue" class="invalid-feedback">
                                                                {{ $t("error_minLengthMsg", [$v.modelName.$params.minLen.min]) }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted"> {{ $t("tag_detailInfoTagTypeModelName") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="newTagTypeForm_referenceInput">{{ $t("tag_tagTypeReferenceLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-barcode"></i></span>
                                                            </div>
                                                            <input v-model="reference" @input="$v.reference.$touch()" type="text" class="form-control" id="newTagTypeForm_referenceInput" :placeholder="$t('tag_inputInTagTypeModelReference')" />
                                                            <div v-if="!$v.reference.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="isReferenceExist" class="invalid-feedback">
                                                                {{ $t("error_referenceExistsMsg", {reference: reference}) }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_detailInfoTagTypeModelReference") }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row validated">
                                                    <div class="col-lg-6">
                                                        <label for="newTagTypeForm_batteryLifetimeInput">{{ $t("tag_tagTypeBatteryMonthLifetimeLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                            </div>
                                                            <input v-model="batteryLifetime" @input="$v.batteryLifetime.$touch()" type="number" min=1 class="form-control" id="newTagTypeForm_batteryLifetimeInput" :placeholder="$t('tag_inputInTagTypeModelBatteryLifetime')" />
                                                            <div v-if="!$v.batteryLifetime.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="!$v.batteryLifetime.integer" class="invalid-feedback">
                                                                {{ $t("error_invalidThresholdNumberType") }}
                                                            </div>
                                                            <div v-if="$v.batteryLifetime.required && $v.batteryLifetime.integer && !$v.batteryLifetime.correctValue" class="invalid-feedback">
                                                                {{ $t("error_invalidThresholdNumberMin") }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_detailInfoTagTypeModelBatteryMonthLifetime") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="newTagTypeForm_rssiOneMeterInput">{{ $t("tag_tagTypeRssiOneMeterLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-broadcast" viewBox="0 0 16 16">
                                                                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <input v-model="rssiOneMeter" @input="$v.rssiOneMeter.$touch()" type="number" class="form-control" id="newTagTypeForm_rssiOneMeterInput" :placeholder="$t('tag_inputInTagTypeModelRSSI')" />
                                                            <div v-if="!$v.rssiOneMeter.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="!$v.rssiOneMeter.integer" class="invalid-feedback">
                                                                {{ $t("error_invalidThresholdNumberType") }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_detailInfoTagTypeModelBatteryMonthLifetime") }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group form-group-last row validated">
                                                    <div class="col-lg-6">
                                                        <label>{{ $t("tag_tagTypeImageLabel") }}</label>
                                                        <div class="custom-file">
                                                            <input type="file" @change="handleFileSelected" ref="imageTagTypeFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectTagTypeImageInputFile" />
                                                            <label class="custom-file-label textLeft" id="selectSiteImageInputLabel" for="selectTagTypeImageInputFile">{{ $t("common_chooseFile") }}</label>
                                                        </div>
                                                        <div v-if="errorImageFile" class="invalid-feedback">
                                                            {{ errorImageFile }}
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_tagTypeSelectImageHelpText") }}</span>
                                                    </div>
                                                    <div v-show="isImageLoaded" class="col-lg-6">
                                                        <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.editTagTypeImage + ')' }"></div>
                                                        <div class="kt-margin-b-10 kt-margin-t-10">{{ editTagTypeImageName }}</div>
                                                        <div>
                                                            <button id="selectTagTypeImageInputFile" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editTagTypeCreateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || isReferenceExist || !isFormDataChanged" >
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editTagTypeCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, macAddress, minLength, integer, minValue } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            tagTypeId: this.$route.params.tagTypeId,
            isFormDataChanged: false,
            modelName: "",
            oldModelName: "",
            reference: "",
            oldReference: "",
            batteryLifetime: null,
            oldBatteryLifetime: null,
            rssiOneMeter: null,
            oldRssiOneMeter: null,
            editTagTypeImage: "",
            oldTagTypeImage: "",
            editTagTypeImageName: "",
            oldTagTypeImageName: "",
            imageFile: null,
            isImageLoaded: false,
            errorImageFile: "",
            isReferenceExist: false,
        };
    },
    created: function() {
        console.log("Component(editTag)::created() - called");
        this.getSiteTagTypes(this.siteId);
        this.getTagTypeById({ siteId: this.siteId, tagTypeId: this.tagTypeId})
    },
    mounted: function() {
        console.log("Component(editTag)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(editTag)::beforeDestroy() - called");
        // Reset component data
        this.modelName = null;
        this.oldModelName = null;
        this.reference = null;
        this.oldReference = null;
        this.batteryLifetime = null;
        this.oldBatteryLifetime = null;
        this.rssiOneMeter = null;
        this.oldRssiOneMeter = null;
        this.editTagTypeImageName = null;
        this.oldTagTypeImageName = null;
        // Reset store
        this.resetTagTypesState();
    },
    destroyed: function() {
        console.log("Component(editTagType)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        modelName: {
            required,
            minLen: minLength(4),
            correctValue: value => value.replace(/\s+/g, ' ').length >= 4
        },
        reference: {
            required,
        },
        batteryLifetime: {
            integer,
            required,
            minValue: 1,
            correctValue: value => value >= 1,
        },
        rssiOneMeter: {
            integer,
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentTagType: function(tagType) {
            console.log("Component(editTagType)::watch(currentTagType) - called with : ", tagType);
            if (tagType) {
                this.modelName = tagType.name;
                this.oldModelName = tagType.name;
                this.reference = tagType.reference;
                this.oldReference = tagType.reference;
                this.batteryLifetime = tagType.batteryLifetime;
                this.oldBatteryLifetime = tagType.batteryLifetime;
                this.rssiOneMeter = tagType.rssiOneMeter;
                this.oldRssiOneMeter = tagType.rssiOneMeter;
                this.editTagTypeImageName = tagType.imageName || "universal.svg";
                this.oldTagTypeImageName = tagType.imageName || "universal.svg";
                this.isImageLoaded = true;
                this.editTagTypeImage = tagType.image || "/assets/tags/universal.svg";
                this.oldTagTypeImage = tagType.image || "/assets/tags/universal.svg";
            }

        },

        user: function(user) {
            console.log("Component(editTag)::watch(user) - called with : ", user);
            if (user) {
                // In case of language change
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
            }
        },
        modelName: function() {
            this.onChangeForm();
        },
        reference: function() {
            if (this.siteTagTypes && this.reference && this.currentTagType) {
                const tagType = this.siteTagTypes.find(tagType => tagType.reference.toLowerCase().replace(/\s+/g, ' ') === this.reference.toLowerCase().replace(/\s+/g, ' '));
                if (tagType && tagType.reference.toLowerCase() !== this.currentTagType.reference.toLowerCase()) {
                    return this.isReferenceExist = true ;
                }
            }
            this.isReferenceExist = false;
            this.onChangeForm();
        },
        rssiOneMeter: function() {
            this.onChangeForm();
        },
        batteryLifetime: function() {
            this.onChangeForm();
        },
        editTagTypeImage: function() {
            this.onChangeForm();
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "siteTagTypes", "currentTagType", "user"]),
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getTagTypeById", "updateTagType", "getSiteTagTypes", "resetTagTypesState"]),

        handleFileSelected() {
            console.log("Component(NewSite)::handleFileSelected() - called");
            if (this.$refs.imageTagTypeFile.files.length > 0) {
                const allowedExtensions = ['.jpg', '.jpeg', '.png'];
                const fileExtension = this.$refs.imageTagTypeFile.files[0].name.split('.').pop().toLowerCase();
                if (this.$refs.imageTagTypeFile.files[0].size > 3145728) {
                    // 3 MB max
                    this.errorImageFile = i18n.t("common_fileTooBig", { fileLimit: "3" })
                    this.editTagTypeImage = ""; 
                    this.editTagTypeImageName = "";
                    this.imageFile = null;
                    this.isImageLoaded = false;
                    this.imageFile = null;
                } else if (!allowedExtensions.includes("." + fileExtension)) {
                    //Only .jpg, .jpeg and .png formats are supported
                    this.errorImageFile = i18n.t("common_fileSupported", {fileSupported: allowedExtensions.join(', ')})
                    this.editTagTypeImage = "";
                    this.editTagTypeImageName = "";
                    this.isImageLoaded = false;
                    this.imageFile = null;
                }
                else {
                    this.errorImageFile = "",
                    this.errorFileTooBig = null;
                    this.imageFile = this.$refs.imageTagTypeFile.files[0];
                    this.editTagTypeImageName = this.imageFile.name;
                    let reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = () => {
                        this.editTagTypeImage = reader.result;
                        this.isImageLoaded = true;
                    };
                }
            }
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editTagType)::onCancelButton() - called");
            this.$router.push({ name: "tagModels", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(editTag)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                tagTypeId: this.tagTypeId,
                name: this.modelName,
                reference: this.reference,
                rssiOneMeter: this. rssiOneMeter,
                batteryLifetime: this.batteryLifetime,
                createAnother: this.createAnother
            };
            if (this.editTagTypeImage && this.editTagTypeImage !== "/assets/tags/universal.svg") {
                data.image = this.editTagTypeImage;
                data.imageName = this.editTagTypeImageName;
            }
            this.updateTagType(data);
        },

        onRemoveImage() {
            this.editTagTypeImage = ""; 
            this.editTagTypeImageName = "";
            this.imageFile = null;
            this.isImageLoaded = false;
            // Force clear of input file to be able to select the same image again
            $("#selectTagTypeImageInputFile").val(null);
            //  Replaceremoved file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
        },

        onChangeForm(event) {
            if(
                this.modelName !== this.oldModelName ||
                this.reference !== this.oldReference ||
                Number(this.batteryLifetime) !==  Number(this.oldBatteryLifetime) ||
                Number(this.rssiOneMeter) !== Number(this.oldRssiOneMeter) ||
                this.editTagTypeImageName !==  this.oldTagTypeImageName ||
                this.editTagTypeImage !== this.oldTagTypeImage
            ) {
                return this.isFormDataChanged = true;
            }
            return this.isFormDataChanged = false;
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditTagType", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.cursorPointer {
    cursor: pointer;
}
.noPortletBorder {
    border: none !important;
}
.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}
</style>
